var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import MuiMenuItem from '@mui/material/MenuItem';
import styled from '@emotion/styled';
import Divider from '../Divider';
const MENU_ITEM_SIZES = {
    small: {
        padding: '6px 12px',
    },
    medium: {
        padding: '10px 12px',
    },
    large: {
        padding: '12px 16px',
    },
};
const defineHoverEffect = (theme, disableHover) => ({
    '&:hover': {
        backgroundColor: disableHover ? 'transparent' : theme.customColors.menu.item.hover,
    },
});
const defineActiveEffect = (theme, disableActive) => ({
    '&:active': {
        backgroundColor: disableActive ? 'transparent' : theme.customColors.menu.item.active,
    },
});
const MuiMenuItemStyled = styled(MuiMenuItem, {
    shouldForwardProp: propName => propName !== 'addDivider' &&
        propName !== 'size' && propName !== 'disableHover' &&
        propName !== 'disableActive' && propName !== 'removePadding' && propName !== 'itemHeight',
})((props) => {
    var _a;
    return (Object.assign(Object.assign({ gap: props.theme.spacing_sizes.m, padding: !props.removePadding ? (_a = MENU_ITEM_SIZES[props.size]) === null || _a === void 0 ? void 0 : _a.padding : 'unset', cursor: props.disableHover ? 'default' : 'pointer', '& .MuiListItemIcon-root': {
            minWidth: 'auto',
        } }, defineHoverEffect(props.theme, Boolean(props.disableHover))), defineActiveEffect(props.theme, Boolean(props.disableActive))));
});
const DropdownMenuItem = (_a) => {
    var { onClick, itemKey, children = undefined, addDivider = false, size, tabIndex = 0 } = _a, props = __rest(_a, ["onClick", "itemKey", "children", "addDivider", "size", "tabIndex"]);
    const handleMenuItemClick = (event) => {
        if (onClick && itemKey) {
            onClick(event, itemKey);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(MuiMenuItemStyled, Object.assign({}, props, { onClick: handleMenuItemClick, size: size, tabIndex: tabIndex, children: children })), addDivider && _jsx(Divider, {})] }));
};
export default DropdownMenuItem;
