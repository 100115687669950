const getDropdownStyles = (theme) => ({
    filled: {
        'primary-light': {
            boxShadowActive: `0px 0px 0px 3px ${theme.customColors.button.activeShadowPrimary}`,
        },
        error: {
            boxShadowActive: '0px 0px 0px 3px rgba(229, 72, 77, 0.20)',
        },
        primary: {
            boxShadowActive: `0px 0px 0px 3px ${theme.customColors.button.activeShadowPrimary}`,
        },
        secondary: {
            boxShadowActive: '0px 0px 0px 3px rgba(136, 144, 150, 0.10)',
        },
        success: {
            boxShadowActive: '0px 0px 0px 3px rgba(48, 164, 108, 0.20)',
        },
        warning: {
            boxShadowActive: '0px 0px 0px 3px rgba(255, 178, 36, 0.20)',
        },
    },
    outline: {
        'primary-light': {
            boxShadowActive: `0px 0px 0px 3px ${theme.customColors.button.activeShadowPrimary}`,
        },
        error: {
            boxShadowActive: '0px 0px 0px 3px rgba(229, 72, 77, 0.20)',
        },
        primary: {
            boxShadowActive: `0px 0px 0px 3px ${theme.customColors.button.activeShadowPrimary}`,
        },
        secondary: {
            boxShadowActive: '0px 0px 0px 3px rgba(136, 144, 150, 0.10)',
        },
        success: {
            boxShadowActive: '0px 0px 0px 3px rgba(48, 164, 108, 0.20)',
        },
        warning: {
            boxShadowActive: '0px 0px 0px 3px rgba(255, 178, 36, 0.20)',
        },
    },
    plain: {
        'primary-light': {},
        error: {
            boxShadowActive: '0px 0px 0px 3px rgba(229, 72, 77, 0.20)',
        },
        primary: {},
        secondary: {
            boxShadowActive: '0px 0px 0px 3px rgba(136, 144, 150, 0.10)',
        },
        success: {
            boxShadowActive: '0px 0px 0px 3px rgba(48, 164, 108, 0.20)',
        },
        warning: {
            boxShadowActive: '0px 0px 0px 3px rgba(255, 178, 36, 0.20)',
        },
    },
    tinted: {
        'primary-light': {
            boxShadowActive: `0px 0px 0px 3px ${theme.customColors.button.activeShadowPrimary}`,
        },
        error: {
            boxShadowActive: '0px 0px 0px 3px rgba(229, 72, 77, 0.20)',
        },
        primary: {
            boxShadowActive: `0px 0px 0px 3px ${theme.customColors.button.activeShadowPrimary}`,
        },
        secondary: {
            boxShadowActive: '0px 0px 0px 3px rgba(136, 144, 150, 0.10)',
        },
        success: {
            boxShadowActive: '0px 0px 0px 3px rgba(48, 164, 108, 0.20)',
        },
        warning: {
            boxShadowActive: '0px 0px 0px 3px rgba(255, 178, 36, 0.20)',
        },
    },
});
export default getDropdownStyles;
