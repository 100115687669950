import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BackIcon } from '@icons/wolfkit-light/arrow-to-line-left-light.svg';
import IconText from '@shared/ui/icons/IconText';
import Icon from '@shared/ui/icons/Icon';
import DropdownMenuItem from '../MenuItem';
export const MENU_BACK_BTN_SIZES = {
    small: 32,
    medium: 40,
    large: 44,
};
const DropdownMenuItemStyled = styled(DropdownMenuItem)(props => ({
    padding: `${props.theme.spacing_sizes.s}px ${props.theme.spacing_sizes.xs * 2}px`,
}));
const IconTextStyled = styled(IconText)(props => ({
    gap: props.theme.spacing_sizes.xs * 2.5,
    '& .MuiTypography-root': {
        color: props.theme.customColors.portfolio.hover,
    },
}));
const DropdownMenuBackButton = ({ size, itemKey, onClick = undefined, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (_jsx(DropdownMenuItemStyled, { size: size, itemKey: itemKey, onClick: onClick, children: _jsx(IconTextStyled, { IconComponent: (_jsx(Icon, { size: 16, IconComponent: BackIcon, color: theme.customColors.portfolio.hover })), text: t('navigation.go_back', { ns: 'common' }) }) }));
};
export default DropdownMenuBackButton;
